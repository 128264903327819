import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { APP_COOKIE_NAME, USER_ID_COOKIE_NAME, LOGOUT, SHOW_NOTIFICATION } from '../constants';
import { deleteCookie, getCookie, setCookie } from '../utils';

export const BASE_URL = 'https://kore-api.kuarasoftware.es';

export const axios = Axios.create({
  baseURL: BASE_URL,
  headers: { 'X-Api-Key': 'MllNrvARAOHkADeaaenzUrqOXArLevFxdm' },
});

const setAccessToken = async (token) => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token || getCookie(APP_COOKIE_NAME)}`,
  };
  setCookie(APP_COOKIE_NAME, token, 1);
};

const chcekAccessToken = async (request) => {
  if (request.headers['Authorization']) {
    return request;
  } else {
    const token = await getCookie(APP_COOKIE_NAME);
    if (token) {
      setAccessToken(token);
      const headers = request.headers;
      return { ...request, headers: { ...headers, Authorization: `Bearer ${token}` } };
    } else {
      return request;
    }
  }
};

const ConnectionHandler = () => {
  const dispatch = useDispatch();

  const showNotification = (message = 'HA OCURRIDO UN ERROR') => {
    dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'error', message } });
  };

  const exitFromApp = () => {
    deleteCookie(APP_COOKIE_NAME);
    deleteCookie(USER_ID_COOKIE_NAME);
    dispatch({ type: LOGOUT });
  };

  axios.interceptors.request.use(
    async (request) => {
      const config = await chcekAccessToken(request);
      return config;
    },
    (err) => {
      const {
        status = 0,
        data: { message = '' },
        config: { url = '' },
      } = err?.response || {};
      console.log('⛔️ REQUEST ERROR (Target)=> ', url);
      console.log('⛔️ REQUEST ERROR (Message)=> ', message);
      console.log('⛔️ REQUEST ERROR (Code) => ', status);
      console.log('➕➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➕');
      showNotification(`${message}`);
      // showNotification(`${message} => TARGET: ${url}`);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      if (config.config.url === `/login/ui`) {
        setAccessToken(config.data.access_token);
        setCookie(USER_ID_COOKIE_NAME, config.data._id, 30);
      }
      return config;
    },
    (err) => {
      const { url, message, status } = (() => {
        if (err.response) {
          const response = err.response;
          return { url: response.config.url, message: response.data.message, status: response.status };
        }
        return { url: err.config.url, message: err.message, status: 0 };
      })();
      console.log('⛔️ RESPONSE ERROR (Target) => ', url);
      console.log('⛔️ RESPONSE ERROR (Message) => ', message);
      console.log('⛔️ RESPONSE ERROR (Code) => ', status);
      console.log('➕➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➖➕');
      switch (status) {
        case 0:
          exitFromApp();
          break;
        case 401:
          exitFromApp();
          break;
        default:
          break;
      }
      showNotification(`${message}`);
      // showNotification(`${message} => TARGET: ${url}`);
    }
  );

  return null;
};

export default ConnectionHandler;
