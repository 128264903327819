import { cloneDeep } from 'lodash';
import { handleActions } from 'redux-actions';
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SET_APPS,
  ADD_APP,
  UPDATE_APP,
  UPDATE_APIKEY,
  SET_SERVICES,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_ESTABLISHMENT_INFO,
  UPDATE_ESTABLISHMENT_INFO,
  ADD_ESTABLISHMENT,
  ADD_SERVICE,
  SET_ESTABLISHMENT_SETTINGS,
  UPDATE_ESTABLISHMENT_SETTINGS,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  SET_ESTABLISHMENTS,
  UPDATE_ESTABLISHMENT,
  SET_SERVICES_LOGS,
  UPDATE_SERVICE,
  UPDATE_EST_USERS,
  DELETE_ESABLISHMENT,
  DELETE_USER,
  DELETE_EST_USERS,
  ADD_DAY_LOGS,
  REMOVE_EST_APP_PERMISSION,
} from './../../constants';
// import { unionBy } from 'lodash';

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

export const apps = handleActions(
  {
    [SET_APPS]: (state, action) => action.payload,
    [ADD_APP]: (state, action) => [...state, action.payload],
    [UPDATE_APP]: (state, action) => {
      const index = state.findIndex((x) => x._id === action.payload._id);
      const newState = state;
      newState[index] = action.payload;
      return newState;
    },
    [UPDATE_APIKEY]: (state, action) => {
      const index = state.findIndex((x) => x._id === action.payload._id);
      const newState = state;
      newState[index].apikey = action.payload.apikey;
      return newState;
    },
  },
  null
);

export const services = handleActions(
  {
    [SET_SERVICES]: (state, action) => action.payload,
    [ADD_SERVICE]: (state, action) => [...state, action.payload],
    [UPDATE_SERVICE]: (state, action) => {
      const index = state?.findIndex((service) => service._id === action.payload._id);
      const newState = cloneDeep(state);
      newState[index] = action.payload;
      return newState;
    },
  },
  null
);

export const logs = handleActions(
  {
    [ADD_DAY_LOGS]: (state, action) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    },
  },
  {}
);

export const servicesLogs = handleActions(
  {
    [SET_SERVICES_LOGS]: (state, action) => action.payload,
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

export const establishments = handleActions(
  {
    [SET_ESTABLISHMENTS]: (state, action) => action.payload,
    [ADD_ESTABLISHMENT]: (state, action) => [...state, action.payload],
    [UPDATE_ESTABLISHMENT]: (state, action) => {
      const index = state.findIndex((x) => x._id === action.payload._id);
      const newState = [...state];
      newState[index] = action.payload;
      return newState;
    },
    [DELETE_ESABLISHMENT]: (state, action) => {
      const index = state.findIndex((x) => x._id === action.payload);
      const newState = cloneDeep(state);
      newState.splice(index, 1);
      return newState;
    },
  },
  null
);

/* ESTABLISHMENT */
export const establishment = handleActions(
  {
    [SET_ESTABLISHMENT_INFO]: (s, action) => ({ ...s, info: action.payload }),
    [UPDATE_ESTABLISHMENT_INFO]: (s, action) => ({ ...s, info: action.payload }),
    [SET_ESTABLISHMENT_SETTINGS]: (s, action) => ({ ...s, settings: action.payload }),
    [UPDATE_ESTABLISHMENT_SETTINGS]: (s, action) => ({ ...s, settings: action.payload }),
  },
  { info: null, settings: { skipHolidays: null, skipWeekend: null } }
);

/* APP */
export const currentRoute = handleActions(
  {
    [SET_CURRENT_ROUTE]: (state, action) => action.payload,
  },
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({ show: false, status: null, message: null }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => [...state, action.payload],
    [UPDATE_USER]: (state, action) => {
      const index = state.findIndex((x) => x._id === action.payload._id);
      const newState = [...state];
      newState[index] = action.payload;
      return newState;
    },
    [REMOVE_EST_APP_PERMISSION]: (state, action) => {
      let newState = cloneDeep(state);
      let estUsers = newState
        .filter((user) => user.establishment === action.payload.establishment)
        .map((user) => ({
          ...user,
          apps: user.apps.filter((app) => app !== action.payload.app),
        }));
      newState = newState.filter((user) => user.establishment !== action.payload.establishment);
      newState = newState.concat(estUsers);
      return newState;
    },
    [UPDATE_EST_USERS]: (state, action) => {
      let newState = cloneDeep(state);
      newState = newState.filter((user) => user.establishment !== action.payload.est);
      newState = newState.concat(action.payload.users);
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      let newState = cloneDeep(state);
      newState = newState.filter((user) => user._id !== action.payload);
      return newState;
    },
    [DELETE_EST_USERS]: (state, action) => {
      let newState = cloneDeep(state);
      newState = newState.filter((user) => user.establishment !== action.payload);
      return newState;
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({ show: !state.show, data: null, loading: false }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({ show: true, data: action.payload, loading: false }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({ ...state, loading: !state.loading }),
  },
  { show: false, data: null, loading: false }
);
