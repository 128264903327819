import { axios } from "../connection/ConnectionHandler";
import { SET_SERVICES, ADD_SERVICE, UPDATE_SERVICE, SET_SERVICES_LOGS } from "../constants";
import { API } from "../constants/api";

export const fetchAllServices = () => {
    return async dispatch => {
        const response = await axios.get(API.services.all)
        .then(response => {
            dispatch({type : SET_SERVICES, payload : response.data});
            return response.status;
        }).catch(err => {
            return err;
        });
        return response;
    }
}

export const fetchAllServicesLogs = () => {
    return async dispatch => {
        const response = await axios.get(API.services.logs)
        .then(response => {
            dispatch({type : SET_SERVICES_LOGS, payload : groupByMonth(response.data)});
            return response.status;
        })
        .catch(err => {
            return err;
        });
        return response;
    }
}

export const createService = (newService) => {
    return async dispatch => {
        const response = await axios.post(API.services.create,newService)
        .then(response => {
            dispatch({type : ADD_SERVICE, payload : response.data});
            return response.status;
        }).catch(err => {
            return err;
        });
        return response;
    }
}

export const updateService = (service) => {
    return async dispatch => {
        const response = await axios.put(`${API.services.update}${service._id}`,service)
        .then(response => {
            dispatch({type : UPDATE_SERVICE, payload : response.data});
            return response.status;
        }).catch(err => {
            return err;
        });
        return response;
    }
}

const getMonthYear = (log) => {
    const stringDate = log.processDate;
    const date = new Date(stringDate);

    return [date.getMonth(), date.getFullYear()];
}

const groupByMonth = (servicesLogs) => {

    let logs = {};

    servicesLogs.forEach(log =>{

        const [month, year] = getMonthYear(log);
        const id = `${log.establishment}_${log.service}_${month}-${year}`;
        const exists = logs[id] != null;

        // add to logs, init object
        if(!exists){
            logs[id] = {
                establishment: log.establishment,
                service : log.service,
                calls : Number(log.calls || 0),
                date : new Date(`${year}-${month+1}-01`),
                logs : [log]
            }
        }

        // sum calls counts
        if(exists){
            logs[id].calls += Number(log.calls || 0)
            logs[id].logs.push(log);
        }
    })

    return Object.keys(logs).map(k => ({ _id : k, ...logs[k]}))
}