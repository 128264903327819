import { createSelector } from 'reselect';
import moment from 'moment';
import 'moment/locale/es';
import { keyBy } from 'lodash';
moment().locale('es');

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(getCurrentRoute, (route) => route.title);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentReportDateFilter = createSelector(
  (state) => state.app,
  (app) => app?.reportSharedData?.currentDateFilter
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) => profile?.role);
export const getCurrentUserEstablishmentId = createSelector(getUserProfile, (profile) => profile?.establishment);

/* ESTABLISHMENT */
export const getEstablishment = createSelector(
  (state) => state.establishment,
  (establishment) => establishment
);
export const getEstablishmentInfo = createSelector(getEstablishment, (establishment) => establishment?.info);
export const getEstablishmentSettings = createSelector(getEstablishment, (establishment) => establishment?.settings);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* CHAT */
export const getChatCurrentUser = createSelector(
  (state) => state.chats,
  (chats) => chats
);
export const getMessagesChat = createSelector(
  (state) => state.messages,
  (messages) => messages
);
export const getCurrentChat = createSelector(
  (state) => state.currentChat,
  (currentChat) => currentChat
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);

export const getUsersByKey = (key) =>
  createSelector(getUsers, (users) => {
    if (!users) {
      return null;
    }
    return keyBy(users, key);
  });

export const getUsersCount = createSelector(
  (state) => state.users,
  (users) => (users ? users.length : -1)
);
/* USERS */

/* ESTABLISHMENTS */
export const getEstablishments = createSelector(
  (state) => state.establishments,
  (establishments) => establishments
);
export const getEstablishmentsByKey = (key) =>
  createSelector(getEstablishments, (ests) => {
    if (!ests) {
      return null;
    }
    return keyBy(ests, key);
  });
export const getEstablishmentById = (id) =>
  createSelector(
    (state) => state.establishments,
    (establishments) => establishments?.find((e) => e._id === id) || null
  );
export const getEstAppPermissions = (id) =>
  createSelector(getEstablishmentById(id), (establishment) => establishment?.apps || null);

export const getEstCount = createSelector(
  (state) => state.establishments,
  (ests) => (ests ? ests.length : -1)
);

export const getEstUsers = (id) =>
  createSelector(getUsers, (users) => {
    const estUsers = users?.filter((x) => x.establishment === id) || [];
    return estUsers;
  });

export const getEstIsExterminated = (id) =>
  createSelector(getUsers, (users) => {
    const estUsers = users ? users.filter((x) => x.establishment === id) : [];
    if (estUsers.length === 0) return false;
    const someEstUserNotExterminated = estUsers.some((x) => {
      if (x.establishment === id && x.exterminated === false) {
        return true;
      }
      return false;
    });
    // someEstUserNotExterminated = true => establishment exterminated == false
    // someEstUserNotExterminated = false => establishment exterminated == true
    return !someEstUserNotExterminated;
  });
/* ESTABLISHMENTS */

/* APPS */
export const getApps = createSelector(
  (state) => state.apps,
  (apps) => apps
);

export const getAppsIds = createSelector(getApps, (apps) => apps?.map((app) => app._id) || null);

export const getAppsCount = createSelector(
  (state) => state.apps,
  (ests) => (ests ? ests.length : -1)
);

export const getAppById = (id) => createSelector(getApps, (apps) => (apps ? apps.find((app) => app._id === id) : null));

/* APPS */

/* ROUTES */
export const getRoutes = createSelector(
  (state) => state.routes,
  (routes) => routes
);

/* USERS FORM */
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* LOGS */
export const getLogs = createSelector(
  (state) => state.logs,
  (logs) => logs
);
