import { combineReducers } from 'redux';
import {
  authorization,
  services,
  servicesLogs,
  profile,
  currentRoute,
  notifications,
  app,
  apps,
  establishment,
  usersForm,
  users,
  establishments,
  logs,
} from './Reducers';

export default combineReducers({
  app,
  apps,
  authorization,
  currentRoute,
  profile,
  notifications,
  establishment,
  usersForm,
  establishments,
  services,
  logs,
  servicesLogs,
  users,
});
