import { axios } from "../connection/ConnectionHandler";
import {
    SET_ESTABLISHMENT_INFO, SET_ESTABLISHMENT_SETTINGS,
    UPDATE_ESTABLISHMENT, UPDATE_ESTABLISHMENT_SETTINGS, UPDATE_ESTABLISHMENT_INFO,
    SHOW_NOTIFICATION,SET_ESTABLISHMENTS,ADD_ESTABLISHMENT, UPDATE_EST_USERS, DELETE_EST_USERS, DELETE_ESABLISHMENT
} from "../constants";
import { API } from "../constants/api";

export const fetchAllEstablishments = () => {
    return async dispatch => {
        axios.get(API.establishment.all)
            .then(response => dispatch({ type : SET_ESTABLISHMENTS, payload : response.data}))
            .catch(err => console.log(err));
    }
}

export const addEstablishment = establishment => {
    return async dispatch => {
        const response = await axios.post(API.establishment.create, establishment)
            .then(response => {
                dispatch({type : ADD_ESTABLISHMENT, payload : response.data});
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Establecimiento creado.' } });
                return response.status;
            })
            .catch(err =>{
                console.log(err);
            })
        return response;
    }
}

export const updateEstablishment = data => {
    return async dispatch => {
        const response = await axios.put(`${API.establishment.update}${data._id}`, data)
            .then(response => {
                dispatch({type : UPDATE_ESTABLISHMENT, payload : data});
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Establecimiento actualizado.' } });
                return response.status;
            })
            .catch(err => {
                console.log(err);
            })
        return response;
    }
}

export const exterminateEstablishment = id => {
    return async dispatch => {
        const response = await axios.put(API.establishment.exterminate(id))
            .then(response => {
                dispatch({type : UPDATE_EST_USERS, payload : {users : response.data, est : id}});
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Establecimiento exterminado.' } });
                return response.status;
            })
            .catch(err => {
                console.log(err);
            })
        return response;
    }
}

export const desexterminateEstablishment = id => {
    return async dispatch => {
        const response = await axios.put(API.establishment.desexterminate(id))
            .then(response => {
                dispatch({type : UPDATE_EST_USERS, payload : {users : response.data, est : id}});
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Establecimiento desexterminado.' } });
                return response.status;
            })
            .catch(err => {
                console.log(err);
            })
        return response;
    }
}

export const deleteEstablishment = id => {
    return async dispatch => {
        const response = await axios.delete(`${API.establishment.delete(id)}`)
        .then(response => {
            dispatch({type : DELETE_EST_USERS, payload : id});
            dispatch({type : DELETE_ESABLISHMENT, payload: id});
            dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Establecimiento eliminado.' } });
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        })

        return response;
    }
}


// INFO
export const fetchEstablishmentInfo = () => {
    return dispatch => {
        axios.get(API.establishment.info)
            .then(response => dispatch({ type: SET_ESTABLISHMENT_INFO, payload: response.data }))
            .catch(err => err);
    };
};

export const updateEstablishmentInfo = info => {
    return async dispatch => {
        const response = await axios.put(API.establishment.info, info)
            .then(response => {
                dispatch({ type: UPDATE_ESTABLISHMENT_INFO, payload: response.data });
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'info', message: 'Datos actualizados.' } });
                return response.status;
            })
            .catch(err => {
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'error', message: 'Ha ocurrido un error.' } })
                return err;
            });
        return response;
    };
};


// SETTINGS
export const fetchEstablishmentSettings = () => {
    return dispatch => {
        axios.get(API.establishment.settings)
            .then(response => dispatch({ type: SET_ESTABLISHMENT_SETTINGS, payload: response.data }))
            .catch(err => err);
    };
};

export const updateEstablishmentSettings = settings => {
    return async dispatch => {
        const response = await axios.put(API.establishment.settings, settings)
            .then(response => {
                dispatch({ type: UPDATE_ESTABLISHMENT_SETTINGS, payload: response.data });
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'info', message: 'Datos actualizados.' } })
                return response.status;
            })
            .catch(err => {
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'error', message: 'Ha ocurrido un error.' } })
                return err;
            });
        return response;
    };
};