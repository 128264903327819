import { axios } from '../connection/ConnectionHandler';
import { API } from '../constants/api';
import { USERS_FORM_TOOGLE_LOADING, SHOW_NOTIFICATION, UPDATE_USER, SET_USER_LIST, DELETE_USER } from '../constants';

export const fetchAllUsers = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.users.get_all)
      .then((response) => {
        dispatch({ type: SET_USER_LIST, payload: response.data });
        return response.status;
      })
      .catch((err) => {
        return err;
      });
    return response;
  };
};

// PROFILE
export const updateProfile = (updatedProfile) => {
  return async (dispatch) => {
    const response = await axios
      .put(API.users.profile, updatedProfile)
      .then((response) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'success', message: 'Datos actualizados.' },
        });
        return response.status;
      })
      .catch((err) => {
        return err;
      });
    return response;
  };
};

// USERS
export const createUser = (newUser) => {
  return async (dispatch) => {
    dispatch({ type: USERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.users.create, newUser)
      .then((response) => {
        dispatch(fetchAllUsers());
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'success', message: 'Usuario creado con éxito.' },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const refetchUsers = () => {
  return async (dispatch) => {
    axios.get();
  };
};

export const updateUser = (updatedUser) => {
  return async (dispatch) => {
    dispatch({ type: USERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.users.edit}${updatedUser?._id}`, updatedUser)
      .then((response) => {
        const user = response.data;
        dispatch({ type: UPDATE_USER, payload: user });
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'success', message: 'Usuario actualizado.' },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const exterminate = (id) => {
  return async (dispatch) => {
    dispatch({ type: USERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(API.users.exterminate(id))
      .then((response) => {
        const user = response.data;
        dispatch({ type: UPDATE_USER, payload: user });
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'success', message: 'Usuario exterminado.' },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const desexterminate = (id) => {
  return async (dispatch) => {
    dispatch({ type: USERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(API.users.desexterminate(id))
      .then((response) => {
        const user = response.data;
        dispatch({ type: UPDATE_USER, payload: user });
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'success', message: 'Usuario desexterminado.' },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateUserPassword = (userId, newPassword) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.users.edit}${userId}`, { password: newPassword })
      .then((response) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'success', message: 'Contraseña actualizada' },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'error', message: 'Ha ocurrido un error al actualizar la contraseña.' },
        });
        return err;
      });
    return response;
  };
};

export const hardDeleteUser = (userId) => {
  return async (dispatch) => {
    const response = await axios
      .delete(`${API.users.hard_delete(userId)}`)
      .then((response) => {
        dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Usuari eliminado' } });
        dispatch({ type: DELETE_USER, payload: userId });
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: 'error', message: 'Ha ocurrido un error al eliminar el usuario.' },
        });
        return err;
      });
    return response;
  };
};
