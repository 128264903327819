// https://testing-core.deploy.kuarasoftware.es/

export const API = {
  auth: {
    login: `/login/ui`,
  },
  log: {
    get: ({ year, month, day }) => {
      if (year && month && day) {
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        return `/logs?year=${year}&month=${month}&day=${day}`;
      }
      return '/logs';
    },
  },
  services: {
    all: '/service',
    create: '/service',
    logs: '/service/log',
    update: '/service/',
  },
  establishment: {
    addPermission: `/establishment/permission`,
    removePermission: (establishment, app) => `/establishment/permission/${establishment}/${app}`,
    all: '/establishment/all',
    update: '/establishment/',
    create: '/establishment',
    info: '/establishment',
    settings: '/establishment/settings',
    exterminate: (id) => `/user/exterminate-establishment/${id}`,
    desexterminate: (id) => `/user/desexterminate-establishment/${id}`,
    delete: (id) => `/establishment/delete/${id}`,
  },
  apps: {
    create: '/app/create',
    all: '/app/all',
    logo: '/app/logo',
    update: '/app/update',
    apikey: '/app/api-key',
  },
  users: {
    addPermission: `/user/permission`,
    removePermission: (user, app) => `/user/permission/${user}/${app}`,

    create: `/register`,
    profile: '/user/profile',
    edit: `/user/`,
    delete: `/user/`,
    hard_delete: (id) => `/user/delete/${id}`,
    get: `/user/`,
    picture: `/user/picture`,
    get_all: '/user/all',
    exterminate: (id) => `/user/exterminate/${id}`,
    desexterminate: (id) => `/user/desexterminate/${id}`,
  },
};
