import { axios } from "../connection/ConnectionHandler"
import { SET_APPS, UPDATE_APP, UPDATE_APIKEY, SHOW_NOTIFICATION, ADD_APP } from "../constants"
import { API } from "../constants/api"

export const fetchAllApps = () => {
    return async dispatch => {
        axios.get(API.apps.all)
        .then(response => {
            dispatch({type : SET_APPS, payload : response.data});
            return response.status;
        })
        .catch(err =>{
            console.log(err);
            return err?.response?.status;
        })
    }
}

export const createApp = (data) => {
    return async dispatch => {
        const response = await axios.post(API.apps.create, data)
        .then( response => {
            console.log(response);
            dispatch({type : ADD_APP, payload : response.data});
            dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Aplicación creada con éxito.' } });
            return response.data._id;
        })
        .catch(
            err => {
                console.log(err);
                return undefined;
            }
        )
        return response;
    }
}

export const updateApp = (data) => {
    return async dispatch => {
        const response = await axios.put(API.apps.update, data)
        .then( response => {
            dispatch({type : UPDATE_APP, payload : response.data});
            dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Aplicación actualizada.' } });
            return response.status;
        })
        .catch( err => {
            console.log(err);
            return err?.response?.status;
        })
        return response;
    }
}

export const updateAppLogo = (data) => {
    return async dispatch => {
        const response = await axios.post(API.apps.logo, data)
        .then( response => {
            dispatch({type : UPDATE_APP, payload : response.data});
            dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'success', message: 'Logo de la aplicación guardado.' } });
            return response.status;
        })
        .catch( error => {
            console.log(error);
            return error?.response?.status
        })
        return response;
    }
}

export const genApiKey = (appID) => {

    console.log(appID);

    const data = {
        app_id : appID
    }

    console.log(data);

    return async dispatch => {
        const response = await axios.put(API.apps.apikey, data)
        .then(response =>{
            dispatch({type : UPDATE_APIKEY, payload : response.data})
            return response.data.apikey;
        })
        .catch( error => {
            console.log(error);
            return undefined;
        })
        return response;
    }
}