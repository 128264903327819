/* BASIC APP CONSTANTS */
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const APP_COOKIE_NAME = 'APP_COOKIE_NAME';
export const USER_COOKIE_NAME = 'USER_COOKIE_NAME';
export const PASS_COOKIE_NAME = 'PASS_COOKIE_NAME';
export const USER_ID_COOKIE_NAME = 'USER_ID_COOKIE_NAME';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SET_SIDE_MENU_STATE = 'SET_SIDE_MENU_STATE';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const SHOW_ACTION_MENU = 'SHOW_ACTION_MENU';
export const HIDE_ACTION_MENU = 'HIDE_ACTION_MENU';
export const SHOW_BOTTOM_TOOLBAR = 'SHOW_BOTTOM_TOOLBAR';
export const HIDE_BOTTOM_TOOLBAR = 'HIDE_BOTTOM_TOOLBAR';
export const SET_USER_LIST = 'SET_USER_LIST';

/* LOGS */
export const ADD_DAY_LOGS = 'ADD_DAY_LOGS';
/* END LOGS */

/* APPS */
export const SET_APPS = 'SET_APPS';
export const ADD_APP = 'ADD_APP';
export const UPDATE_APP = 'UPDATE_APP';
export const UPDATE_APIKEY = 'UPDATE_APIKEY';

/* <---- BASIC APP CONSTANTS ----> */
/* USER */
export const SET_PROFILE = 'SET_PROFILE';
/* <---- USER ----> */

/* ESTABLISHMENT */
export const UPDATE_ESTABLISHMENT = 'UPDATE_ESTABLISHMENT';
export const SET_ESTABLISHMENTS = 'SET_ESTABLISHMENTS';
export const ADD_ESTABLISHMENT = 'ADD_ESTABLISHMENT';
export const SET_ESTABLISHMENT_INFO = 'SET_ESTABLISHMENT_INFO';
export const UPDATE_ESTABLISHMENT_INFO = 'UPDATE_ESTABLISHMENT_INFO';
export const SET_ESTABLISHMENT_SETTINGS = 'SET_ESTABLISHMENT_SETTINGS';
export const UPDATE_ESTABLISHMENT_SETTINGS = 'UPDATE_ESTABLISHMENT_SETTINGS';
export const DELETE_ESABLISHMENT = 'DELETE_ESTABLISHMENT';
/* <---- ESTABLISHMENT ----> */

/* USERS */
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const OPEN_USERS_FORM = 'OPEN_USERS_FORM';
export const CLOSE_USERS_FORM = 'CLOSE_USERS_FORM';
export const EDIT_SELECTED_USER = 'EDIT_SELECTED_USER';
export const USERS_FORM_TOOGLE_LOADING = 'USERS_FORM_TOOGLE_LOADING';
export const UPDATE_EST_USERS = 'UPDATE_EST_USERS';
export const DELETE_EST_USERS = 'DELETE_EST_USERS';
/* <---- USERS ----> */

export const SET_SERVICES = 'SET_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const SET_SERVICES_LOGS = 'SET_SERVICES_LOGS';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';

/* ROUTES */
export const SET_ALL_ROUTES = 'SET_ALL_ROUTES';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
/* <---- ROUTES ----> */

/* APP PERMISSION */
export const REMOVE_EST_APP_PERMISSION = 'REMOVE_EST_APP_PERMISSION';
/* ------------- */
